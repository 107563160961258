import React from "react";
import "./Header.scss";
import { motion } from "framer-motion";
import images from "../../constants/images";
import { AppWrap } from "../../wrapper";
import "./custom-style.scss";

const scaleVariant = {
  scale: [0, 1],
  opacity: [0, 1],
  transition: {
    duration: 1,
    ease: "easeInOut",
  },
};


const Header = () => {
  return (
    <div id="home" className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          {/* TODO: Add The hand wave effect */}
          <div className="badge-cmp app__flex">
            <div>
              <span class="wave">👋</span>
            </div>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello, I am</p>
              <h1 className="head-text">Quan</h1>
            </div>
          </div>
          <div className="tag-custom">
            <div className="tag-cmp app__flex">
              <p className="p-text">Software Developer</p>
              <p className="p-text">FreeLance</p>
            </div>

            <a target="_blank" href="https://www.linkedin.com/in/lennmquan/">
              <button className={"learn-more"}>Say Hi</button>
            </a>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
      </motion.div>

      {/* <motion.div
        variant={scaleVariant}
        whileInView={scaleVariant.whileInView}
        className="app__header-circles"
      >
      {[images.flutter, images.redux, images.sass].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`cirlce-${circle}`}>
          <img src={circle} alt="circle" />
        </div>
      ))}
        
      </motion.div> */}
    </div>
  );
};

export default AppWrap(Header, "home");
