import React, { useState } from "react";
import style from "./Navbar.module.scss";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

const resumeLink = "https://drive.google.com/file/d/17Ll4WAUER2NBJftFDzMtdvt951Hdardi/view?usp=drive_link"

  
const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className={style['app__navbar']}>
      <div 
      style={{color: 'black'}}
      className={style['app__navbar-logo']}>
        QL
      </div>

      <ul className={style['app__navbar-links']}>
          {["home", "about", "work", "skills", "contact"]?.map((item) => (
            <li
              key={item} 
              className="app__flex p-text">
              <div></div>

              <a href={`#${item}`}>{item}</a>
            </li>
          ))}
          <li className="app__flex p-text">
              <div></div>
            <a target="_blank" href={resumeLink}>resume</a>
          </li>
      </ul>

      <div className={style['app__navbar-menu']}>
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {/* Dynamics  code block */}
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.085, ease: "easeIn" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className={style['app__navbar-links']}>
            {["home", "about", "work", "skills", "contact"]?.map((item) => (
              <li key={item} className="app__flex p-text">

                <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
              </li>
            ))}
            <li className="app__flex p-text">
              <div></div>
            <a target="_blank" href={resumeLink}>resume</a>
            </li>
            </ul>
             
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
