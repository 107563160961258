import './App.scss';
import {About, Footer, Work, Skills, Testimonials, Header} from './container';

import Navbar from "./components/Navbar/Navbar";
import { SocialMedia } from './components';
function App() {
  return (
    <div className="app">
      <Navbar/>
      <Header/>
      <About />
      <Work />
      <Skills />
      {/* <Testimonials /> */}
      <Footer />
      
    </div>
  );
}

export default App;
