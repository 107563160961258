import React, { useState, useEffect } from "react";
import "./Work.scss";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import { Typography, Stack } from "@mui/material";
// get the C++, react, vercel, django, express, nodejs icon from react-icons
import { SiCplusplus } from "react-icons/si";
import { DiReact, DiDjango, DiNodejsSmall } from "react-icons/di";

// map icons with their tags
const icons = {
  "c++": <SiCplusplus />,
  react: <DiReact />,
  django: <DiDjango />,
  nodejs: <DiNodejsSmall />,
};

const Work = () => {
  const [activeFilter, setfActiveFilter] = useState(false);

  // Dynamicall set animation
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWorks, setFilterWorks] = useState([]);

  // Fetch the data from backend
  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      // Filter data by priority, priority is a number from 1 to 5
      data.sort((a, b) => a.priority - b.priority);
      setWorks(data);
      setFilterWorks(data);
    });
  }, []);

  // Get all the tags from the works array of objects
  // The tags are used to filter the works
  // and they are an arrays of strings

  const tags = works.map((work) => work.tags).flat();
  // Sort the tags by alphabetical order
  const uniqueTags = [...new Set(tags)].sort();
  console.log(works);
  // Handle the filter of the works

  const handleWorkFilter = (item) => {
    setfActiveFilter(item);
    setAnimateCard({ y: 100, opacity: 0 });

    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });

      // sort work by priority, priority is a number from 1 to 5

      

      if (item === "All") {
        setFilterWorks(works);
      } else {
        setFilterWorks(works.filter((work) => work.tags.includes(item)));
      }

    });
  };

  console.log(works);

  return (
    <>
      <h2 className="head-text">
        My <span> Work </span>{" "}
      </h2>
      <div className="app__work-filter">
        {uniqueTags.map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWorks.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <>
                <img src={urlFor(work.imgUrl)} alt={work.name} />
              </>

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>

                <a href={work.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <Typography mt={2} fontStyle="bold" variant="h6" gutterBottom>
                {work.title}
              </Typography>
              <Typography
                color="black"
                mt={2}
                variant="body1"
                fontSize={"0.9rem"}
                gutterBottom
              >
                {work?.description}
              </Typography>
              {/* Map the icon based on the tools */}
              <Stack direction="row">
                <button className="app__work-btn">
                  <a href={work.projectLink} style={{color: 'white'}}>Demo</a></button>
                <button className="app__work-btn" ><a style={{color: 'white'}} href={work.codeLink}>Code</a></button>
              </Stack>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
