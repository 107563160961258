import React from 'react'
import {BsTwitter, BsInstagram, BsLinkedin, BsGithub} from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa';
import style from './SocialMedia.module.scss'

const SocialMedia = () => {
  return (
    <div className={style['app__social']}>
         <div>
          <a href="https://www.linkedin.com/in/lennmquan/">
            <BsLinkedin/>
          </a>
        </div>
        <div>
          <a href="https://github.com/lucasLe1709?tab=repositories">
            <BsGithub />
          </a>
        </div>
        <div>
            <BsTwitter />
        </div>
        <div>
            <FaFacebookF />
        </div>
        <div>
            <BsInstagram />
        </div>
    </div>
  )
}

export default SocialMedia